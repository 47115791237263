import { FelaCSS, colors, spacing } from '@bridebook/ui';

interface IStyles {
  button: FelaCSS;
  buttonInner: FelaCSS;
  buttonWrapper: FelaCSS;
  heading: FelaCSS;
  info: FelaCSS;
  textWrapper: FelaCSS;
  wrapper: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    alignItems: 'center',
    margin: spacing[16] + ' 0 0',
    maxWidth: '330px',
    padding: 0,
    maxWidthMobileSm: {
      margin: 8,
    },
  },

  heading: {
    fontDefaultSemiBold: 24,
    maxWidthMobileSm: {
      fontDefaultSemiBold: 26,
    },
  },
  info: {
    fontDefault: 18,
    color: colors.space60,
  },
  textWrapper: {
    textAlign: 'center',
  },
  button: {
    flex: 1,
    justifyContent: 'center',
  },
  buttonWrapper: {
    margin: '0',
    flex: 1,
    maxWidthMobile: {
      bottom: spacing[8],
      left: 0,
      margin: [0, spacing[8], 0].join(' '),
      position: 'fixed',
      right: 0,
    },
  },

  buttonInner: {
    flex: 1,
    fontDefault: 16,
    maxWidth: '330px',
  },
});

export default styles;
