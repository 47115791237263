import { Action } from 'lib/types';

export enum WeddingWebsiteAnalyticsTypes {
  NAVIGATION_CLICKED = 'Navigation clicked',
  SECTION_CLICKED = 'Section clicked',
  FEATURE_ONBOARDING_STARTED = 'Started feature onboarding',
  FEATURE_ONBOARDING_STEP_COMPLETED = 'Completed feature onboarding step',
  FEATURE_ONBOARDING_FINISHED = 'Finished feature onboarding',
  INTERACTED_WITH_WEDDING_WEBSITE = '[Analytics][Wedding Website] Interacted with wedding website',
  PERFORMED_SUPPLIER_SEARCH_BY_NAME = '[Analytics][Wedding Website] Performed supplier search by name',
  BOOKED_SUPPLIER = "BOOKED_SUPPLIER",
  SAVED_WEDDING_WEBSITE = '[Analytics][Wedding Website] Saved wedding website',
}

export enum WeddingWebsiteAnalyticEvents {
  INTERACTED_WITH_WEDDING_WEBSITE = 'Interacted with wedding website',
  COMPLETED_FEATURE_ONBOARDING_STEP = 'Completed feature onboarding step',
  SAVED_WEDDING_WEBSITE = 'Saved wedding website',
}

export type WeddingWebsiteSectionType =
  | 'weddingWebsites_details'
  | 'weddingWebsites_design'
  | 'weddingWebsites_security'
  | 'weddingWebsites_rsvp'
  | 'weddingWebsites_guestlist';

export type WeddingWebsiteCTAType =
  | 'Wedding details'
  | 'Website design'
  | 'Privacy & Access'
  | 'Set up RSVP'
  | 'Guest list';

export interface WeddingWebsiteOnboardingAction extends Action {
  type: WeddingWebsiteAnalyticsTypes.FEATURE_ONBOARDING_STARTED;
  payload: {
    category: string;
    location: string;
  };
}

export interface SectionConfig {
  section: WeddingWebsiteSectionType;
  cta: WeddingWebsiteCTAType;
}

export interface IInteractedWithWeddingWebsiteAnalytics {
  event?: WeddingWebsiteAnalyticEvents;
  action: string;
  location: string;
  section: string;
  element: string;
  elementLabel?:
    | string
    | {
        font: string;
        palette: string;
      };
}

export interface SavedWeddingWebsiteAnalytics {
  event: WeddingWebsiteAnalyticEvents.SAVED_WEDDING_WEBSITE;
  location: 'preview' | 'details' | 'design';
  section?: string;
  cta?: 'Save';
  rsvpVisible: boolean;
  ourStoryVisible: boolean;
  eventsVisible: boolean;
  faqVisible: boolean;
  additionalSections: boolean;
  venuePhotos: boolean;
  category: 'weddingWebsites';
}
