import { find, pick, propEq, values } from 'ramda';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { HttpStatusCode } from '@bridebook/toolbox/src/api/http-status';
import { IRelatedTaskData } from 'app-shared/lib/articles/types';
import {
  IArticleTag,
  getArticleTags,
  getExtraArticleTags,
} from 'lib/articles/constants/articleTags';

/**
 * get tag name
 * get tag name of specified article tag by slug
 * @method getTagName
 * @param {string} slug - tag slug to get name for
 */
export const getTagName = (slug: string): string => {
  let name = '';
  if (slug) {
    const tag = find(propEq('value', slug), values(getArticleTags()));
    if (tag) {
      name = tag.label;
    } else {
      const extraTags = getExtraArticleTags();

      if (extraTags[slug]) name = extraTags[slug];
    }
  }
  return name;
};

/**
 * fetchRelatedTaskDataPromise
 * utility for fetching task data from API
 * @param taskId specific task id
 */
export const fetchRelatedTaskDataPromise = (taskId: string): Promise<IRelatedTaskData> =>
  authenticatedFetch(`/api/articles/article-related-tasks`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ query: { taskId } }),
  }).then((res) => {
    if (res.status !== HttpStatusCode.OK) {
      throw new Error('Failed to fetch article related task data');
    }

    return res.json();
  });

/**
 * gets article related checklist task data
 * @method fetchRelatedTaskData
 * @returns {Object} - specific task data
 * @param checklistTaskId
 */
export const fetchRelatedTaskData = async (
  checklistTaskId: string,
): Promise<IRelatedTaskData | null> => {
  let relatedTaskData: IRelatedTaskData | null = null;

  if (checklistTaskId) {
    relatedTaskData = await fetchRelatedTaskDataPromise(checklistTaskId);
  }

  return relatedTaskData;
};

/**
 * gets article tags array
 * @method getArticleTagsList
 * @param {boolean} onlyLiteTags - get only lite version tags
 * @returns {Array} - tags array
 */
export const getArticleTagsList = (onlyLiteTags?: boolean): Array<IArticleTag> => {
  const articleTags = getArticleTags();
  const tagsObj = onlyLiteTags ? pick(['all'], articleTags) : articleTags;

  return values(tagsObj);
};

export const checkIfRelative = (url: string, host: string): boolean =>
  url.indexOf('http') < 0 && url.indexOf(`/${host}`) < 0;
