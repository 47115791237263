import type { CSSProperties } from 'react';

export enum Palettes {
  Bridebook = 'bridebook',
  Foliage = 'foliage',
  Roses = 'roses',
  Whimsical = 'whimsical',
}

export enum Fonts {
  Playfair = 'playfair',
  OpenSans = 'opensans',
  OrelegaOne = 'orelegaone',
  Montserrat = 'montserrat',
  Aboreto = 'aboreto',
  Figtree = 'figtree',
  MeowScript = 'meowscript',
  EBGaramond = 'ebgaramond',
}

export enum FontPairs {
  Bridebook = 'bridebook',
  Foliage = 'foliage',
  Roses = 'roses',
  Whimsical = 'whimsical',
}

export type TWebsiteThemeId = `${Palettes}-${FontPairs}`;

export type TWebsiteTheme = {
  palette: Palettes;
  font: FontPairs;
};

export type TFontsPair = {
  primary: Fonts;
  secondary: Fonts;
};

export type TColorPaletteHighlights = [primary: string, secondary: string, tertiary: string];

export interface IFontStyle {
  fontFamily: Required<CSSProperties>['fontFamily'];
  fontWeight: Required<CSSProperties>['fontWeight'];
  fontUrl: string;
  lineHeight?: CSSProperties['fontWeight'];
}

// onboarding - store types
interface IWeddingWebsiteOnboardingMetaProperties {
  open: boolean;
  activeStep: number;
}

// extra settings for form values, such as marking date/venue as unknown
export interface IWeddingWebsiteOnboardingFormConfig {
  weddingDateNotConfirmed: boolean;
  venueNotConfirmed: boolean;
}

export interface IWeddingWebsiteOnboardingState extends IWeddingWebsiteOnboardingMetaProperties {
  formConfig: IWeddingWebsiteOnboardingFormConfig;
}

export interface INamesFormFields {
  name: string;
  partnerName: string;
}
export interface IDateFormFields {
  weddingDate?: string;
}

export interface IOnboardingFormFields {
  name: string;
  partnerName: string;
  weddingDate?: string;
  venueId?: string;
  theme: TWebsiteThemeId;
  photo?: string;
  weddingDateUndecided: boolean;
  venueIdUndecided: boolean;
}

export interface IWeddingWebsiteDesignTabsState {
  isThemesOpen: boolean;
  isCustomiseOpen: boolean;
}
