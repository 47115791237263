import {
  IWedding_Website,
  IWedding_Website_Section_PhotosField,
  IWedding_Website_Section_QuestionAnswerField,
  IWedding_Website_Section_TextField,
  IWedding_Website_Section_VenueField,
} from '@bridebook/models/source/models/Weddings.types';
import { FontPairs, Palettes } from '../types';

export const venueSection: IWedding_Website_Section_VenueField = {
  data: {
    supplier: '',
    useVenuePhotos: false,
  },
  name: 'primaryVenue',
  type: 'VenueField',
  visible: true,
};

export const photosSection: IWedding_Website_Section_PhotosField = {
  data: [],
  name: 'primaryPhotos',
  type: 'PhotosField',
  visible: true,
};

export const ourStorySection: IWedding_Website_Section_TextField = {
  data: {
    value: '',
  },
  name: 'ourStory',
  type: 'TextField',
  visible: true,
};

export const faqSection: IWedding_Website_Section_QuestionAnswerField = {
  data: [],
  name: 'faq',
  type: 'QuestionAnswerField',
  visible: true,
};

export const EMPTY_WEBSITE_TEMPLATE: IWedding_Website = {
  config: {
    theme: {
      font: FontPairs.Bridebook,
      palette: Palettes.Bridebook,
    },
  },
  privacy: {
    pin: '',
    published: false,
  },
  sections: [photosSection, ourStorySection, venueSection, faqSection],
};
