import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { NewOnboardingAnalyticsAction } from 'lib/onboarding-new/analytics-actions';
import { Action } from 'lib/types';
import { WeddingActionTypes } from 'lib/weddings/action-types';
import {
  IInteractedWithWeddingWebsiteAnalytics,
  WeddingWebsiteAnalyticEvents,
  WeddingWebsiteAnalyticsTypes,
} from './action-types';

export default function weddingWebsiteAnalytics(
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
) {
  const { type, payload } = action;
  const { track } = bridebookAnalytics.getMethods('weddingWebsites');

  switch (type) {
    case WeddingWebsiteAnalyticsTypes.NAVIGATION_CLICKED:
    case WeddingWebsiteAnalyticsTypes.SECTION_CLICKED:
    case WeddingWebsiteAnalyticsTypes.FEATURE_ONBOARDING_STARTED:
      track({
        event: type,
        ...payload,
      });
      break;
    case WeddingWebsiteAnalyticsTypes.INTERACTED_WITH_WEDDING_WEBSITE:
      track({
        event: type,
        ...(payload as IInteractedWithWeddingWebsiteAnalytics),
      });
      break;
    case WeddingActionTypes.UPDATE_WEDDING_FIELD:
      if (payload.name === 'date') {
        track({
          event: NewOnboardingAnalyticsAction.EditedWeddingDetails,
          ...payload,
          weddingDetailsLocation: 'weddingWebsites_onboarding',
          weddingDetailsField: 'date',
        });
      }
      break;
    case WeddingWebsiteAnalyticsTypes.SAVED_WEDDING_WEBSITE:
      track({
        event: WeddingWebsiteAnalyticEvents.SAVED_WEDDING_WEBSITE,
        ...payload,
      });
      break;
    case WeddingWebsiteAnalyticsTypes.PERFORMED_SUPPLIER_SEARCH_BY_NAME:
      track({
        event: type,
        ...(payload as IInteractedWithWeddingWebsiteAnalytics),
      });
      break;
    case WeddingActionTypes.WEBSITE_ONBOARDING_STEP_FORWARD: {
      track({
        event: WeddingWebsiteAnalyticEvents.COMPLETED_FEATURE_ONBOARDING_STEP,
        category: 'weddingWebsites',
        ...payload,
      });
      break;
    }
    case WeddingWebsiteAnalyticsTypes.FEATURE_ONBOARDING_FINISHED:
      track({
        event: WeddingWebsiteAnalyticsTypes.FEATURE_ONBOARDING_FINISHED,
        ...payload,
      });
      break;
  }
}

export * from './actions';
