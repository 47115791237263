import produce from 'immer';
import { AuthActionTypes } from 'lib/auth/action-types';
import { SearchActionTypes } from 'lib/search/action-types';
import { Action, IReducersImmer, UiState } from 'lib/types';
import { INITIAL_ACHIEVEMENT_HIDE_STATUS } from 'lib/ui/constants';
import { getReducerActions } from 'lib/utils';
import {
  IChangeAchievementPopupIgnoreStatus,
  IFetchBadgesSuccessAction,
  ISettingsToggleChangeEmailFormAction,
  ISettingsToggleLinkEmailFormAction,
  IShowMobileNavAction,
  IToggleFullscreenImageModalAction,
  IToggleLostLeadModal,
  IToggleNavMenuDrawerAction,
  ITogglePushNotificationOptInAction,
  IToggleSpecialOfferPopupAction,
  UIActionTypes,
} from './action-types';

const initialState: UiState = {
  badgesLoaded: false,
  barIsVisible: true,
  scrollSpyCurrentTarget: { active: false },
  scrollSpyScrolling: false,
  shareUrlCopied: false,
  settingsShowLinkEmailForm: false,
  settingsShowChangeEmailForm: false,
  mobileKeyboardIsOpened: false,
  pushNotificationOptInShown: false,
  showNavMenuDrawer: false,
  specialOfferPopupType: null,
  specialOfferPopupOffer: null,
  fullScreenImageModal: {
    show: false,
    imageUrl: '',
  },
  searchByNameModal: {
    show: false,
  },
  searchByLocationModal: {
    show: false,
  },
  achievementPopupIgnore: INITIAL_ACHIEVEMENT_HIDE_STATUS,
  achievements: {},
  mobileNavShown: false,
  lostLeadModal: {
    show: false,
    supplierId: null,
  },
  isRatingModalVisible: false,
  isCalendlyModalVisible: false,
};

const reducers: IReducersImmer<UiState> = (draft) => ({
  [UIActionTypes.TOGGLE_SEARCH_BAR]: (action) => {
    draft.barIsVisible = action.payload;
  },

  [UIActionTypes.SET_ACHIEVEMENT_UNLOCKED]: (action) => {
    draft.achievements[action.payload.achievement.id] = {
      ...action.payload.achievement,
      isOpen: true,
    };
  },

  [UIActionTypes.SET_NEXT_ACTION]: (action) => {
    draft.nextAction = action.payload.nextAction;
  },

  [UIActionTypes.SHOW_NEXT_ACTION]: () => {
    if (draft.nextAction) {
      draft.nextAction.active = true;
    }
  },

  [UIActionTypes.HIDE_NEXT_ACTION]: () => {
    if (draft.nextAction) {
      draft.nextAction.active = false;
    }
  },

  [UIActionTypes.COMPLETE_NEXT_ACTION]: () => {
    if (draft.nextAction) {
      draft.nextAction.active = false;
    }
  },

  [UIActionTypes.HIDE_ACHIEVEMENT]: (action) => {
    draft.achievements[action.payload.id].isOpen = false;
  },

  [UIActionTypes.COPIED_URL]: () => {
    draft.shareUrlCopied = true;
  },

  [UIActionTypes.RESET_COPIED_URL]: () => {
    draft.shareUrlCopied = false;
  },

  [UIActionTypes.TOGGLE_LINK_EMAIL_FORM]: (action: ISettingsToggleLinkEmailFormAction) => {
    draft.settingsShowLinkEmailForm = action.payload;
  },

  [UIActionTypes.TOGGLE_SETTINGS_CHANGE_EMAIL_FORM]: (
    action: ISettingsToggleChangeEmailFormAction,
  ) => {
    draft.settingsShowChangeEmailForm = action.payload;
  },

  [UIActionTypes.TOGGLE_PUSH_NOTIFICATION_OPT_IN]: (action: ITogglePushNotificationOptInAction) => {
    draft.pushNotificationOptInShown = action.payload;
  },

  [UIActionTypes.TOGGLE_NAV_MENU_DRAWER]: (action: IToggleNavMenuDrawerAction) => {
    draft.showNavMenuDrawer = action.payload;
  },

  [UIActionTypes.TOGGLE_SPECIAL_OFFER_POPUP]: (action: IToggleSpecialOfferPopupAction) => {
    const { type, offer } = action.payload;

    draft.specialOfferPopupOffer = offer;
    draft.specialOfferPopupType = type;
  },

  [UIActionTypes.FETCH_BADGES_SUCCESS]: (action: IFetchBadgesSuccessAction) => {
    draft.achievements = { ...action.payload };
    draft.badgesLoaded = true;
  },

  [UIActionTypes.TOGGLE_FULLSCREEN_IMAGE_MODAL]: (action: IToggleFullscreenImageModalAction) => {
    draft.fullScreenImageModal = action.payload;
  },

  [SearchActionTypes.TOGGLE_SEARCH_BY_NAME_MODAL]: (action: { payload: boolean }) => {
    draft.searchByNameModal.show = action.payload;
  },

  [UIActionTypes.SHOW_MOBILE_NAV]: (action: IShowMobileNavAction) => {
    draft.mobileNavShown = action.payload;
  },

  [UIActionTypes.TOGGLE_NOT_INTERESTED_IN_SUPPLIER_MODAL]: (action: IToggleLostLeadModal) => {
    draft.lostLeadModal = action.payload;
  },

  [UIActionTypes.TOGGLE_RATING_MODAL]: () => {
    draft.isRatingModalVisible = !draft.isRatingModalVisible;
  },

  [UIActionTypes.CHANGE_ACHIEVEMENT_POPUP_IGNORE_STATUS]: (
    action: IChangeAchievementPopupIgnoreStatus,
  ) => {
    draft.achievementPopupIgnore = action.payload;
  },

  /* ############################################################################
   *  AUTH handlers
   * ######################################################################### */

  [AuthActionTypes.SIGN_OUT_COMPLETED]: () => ({
    ...initialState,
  }),
});

const reducersActions = getReducerActions(reducers);

const reducer = (state: UiState = initialState, action: Action): UiState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft: UiState) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
