import { AuthActionTypes } from 'lib/auth/action-types';
import { MobileAppActionTypes } from 'lib/mobile-app/action-types';
import { SearchActionTypes } from 'lib/search/action-types';
import { AppAction, AppState } from 'lib/types';
import {
  getNewPilingCounter,
  isSupplierGalleryPage,
  isToolPage,
  removeMarketFromPath,
} from 'lib/utils/url';
import { initialIsMainLiveCDN } from './constants';

const initialState: AppState = {
  online: true,
  started: false,
  version: null,
  pathname: '',
  serverPage: '',
  query: {},
  asPath: '',
  previousPath: '',
  lastPath: '',
  nextUrl: '',
  redirectToAfterOnboarding: null,
  url: '',
  previousQuery: {},
  previousSearch: '',
  previousSearchQuery: {},
  scrollY: 0,
  scrollYSearch: 0,
  prevScrollY: 0,
  mobileKeyboardOpened: false,
  groups: {},
  groupsLoaded: false,
  startedMainNavigationCounter: 0,
  navigationJourneyStartPath: '',
  pilingCounter: 0,
  canonicalLink: '',
  alternateLinks: [],
  device: {
    analyticsContext: null,
    height: 0,
    host: 'bridebook.com',
    isAndroid: false,
    isChrome: false,
    isCordova: false,
    isIE: false,
    isiOS: false,
    isMac: false,
    isLandscape: false,
    isMainLiveCDN: initialIsMainLiveCDN,
    isMobileSafari: false,
    isMobileUA: false,
    isTabletUA: false,
    sessionId: '',
    versionMajor: -1,
    width: 0,
    serverCountryCode: null,
  },
  deviceServerSet: false,
  deviceClientSet: false,
  locale: '',
};

/**
 * WARNING!
 * This reducer is not being reset upon sign out
 */
const reducer: (state: AppState, action: AppAction) => AppState = (
  state: AppState = initialState,
  action: AppAction,
): AppState => {
  switch (action.type) {
    case 'SET_APP_ONLINE': {
      return { ...state, online: action.payload.online };
    }
    case 'SET_PATHNAME': {
      return {
        ...state,
        previousPath: state.pathname,
        pathname: action.payload,
      };
    }
    case 'SET_DEVICE': {
      return {
        ...state,
        device: { ...state.device, ...action.payload },
        deviceServerSet: true,
        deviceClientSet: typeof window !== 'undefined',
      };
    }
    case 'APP_STARTED': {
      const isTool = isToolPage(state.pathname) || state.pathname.startsWith('/search');
      return {
        ...state,
        started: true,
        startedMainNavigationCounter: isTool ? 1 : 0,
        navigationJourneyStartPath: state.pathname,
      };
    }
    case 'ROUTE_CHANGE_COMPLETE': {
      const { url, query, serverPage } = action.payload;
      const pathname = removeMarketFromPath(url, query.market).split('?')[0];

      if (query.searchPopup) return { ...state };

      const startedMainNavigationCounter = (() => {
        // FIXME: Unfortunately this is a workaround for a supplier gallery page url,
        // which shouldn't have any effect on the back button.
        // Back button logic needs to be rewritten because it's impossible to figure out now.
        if (isSupplierGalleryPage(pathname) || isSupplierGalleryPage(state.previousPath)) {
          return state.startedMainNavigationCounter;
        }

        return state.startedMainNavigationCounter > 0 &&
          state.navigationJourneyStartPath === pathname
          ? 1
          : state.startedMainNavigationCounter + 1;
      })();

      const pilingCounter = getNewPilingCounter(
        state.pathname,
        state.previousPath,
        state.pilingCounter,
      );

      return {
        ...state,
        device: { ...state.device },
        previousSearch: state.previousSearch,
        previousSearchQuery: state.previousSearchQuery,
        pathname,
        query: query || {},
        previousQuery: state.query,
        serverPage: serverPage || '',
        navigationJourneyStartPath:
          startedMainNavigationCounter === 1 ? pathname : state.navigationJourneyStartPath,
        startedMainNavigationCounter,
        pilingCounter,
      };
    }

    case 'ROUTE_CHANGE_START': {
      const { url, urlWithoutMarket, query, nextUrl, locale } = action.payload;
      return {
        ...state,
        lastPath: urlWithoutMarket,
        previousSearch: url.includes('/search') ? url : state.previousSearch,
        previousSearchQuery: url.includes('/search') ? query || {} : state.previousSearchQuery,
        nextUrl,
        url: '/' + locale + url,
        locale,
      };
    }

    case 'FETCH_GROUPS_SUCCESS':
      return { ...state, groups: action.payload, groupsLoaded: true };

    case 'RESIZE_WINDOW':
      return { ...state, device: { ...state.device, ...action.payload } };
    case 'SCROLL_SEARCH_WINDOW':
      return {
        ...state,
        scrollYSearch: action.payload,
      };
    case 'ON_APP_VERSION':
      return { ...state, version: action.payload };

    case SearchActionTypes.CLEAR_ALL_SEARCH:
      return { ...state, previousSearch: '' };

    case 'CHECK_IF_CORDOVA_AGAIN':
      return {
        ...state,
        device: { ...state.device, isCordova: action.payload },
      };

    case 'TOGGLE_MOBILE_KEYBOARD':
      return { ...state, mobileKeyboardOpened: action.payload };

    case 'SET_META_LINKS': {
      const { canonical, alternate } = action.payload;
      return {
        ...state,
        ...(canonical && { canonicalLink: canonical }),
        ...(alternate && { alternateLinks: alternate }),
      };
    }

    case 'DECREMENT_PILING_COUNTER': {
      return { ...state, pilingCounter: state.pilingCounter > 1 ? state.pilingCounter - 2 : 0 };
    }

    case AuthActionTypes.SAVE_NEXT_PATH_AFTER_ONBOARDING: {
      return { ...state, redirectToAfterOnboarding: action.payload };
    }

    case MobileAppActionTypes.WHICH_HANDHELD_DEVICE_TYPE: {
      const { isiOS, isAndroid } = action.payload;
      return { ...state, device: { ...state.device, isiOS, isAndroid } };
    }

    default:
      return state;
  }
};

export default reducer;
