import { getScreenType } from 'lib/app/selectors';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { getCountryCode, getWeddingLocale } from 'lib/i18n/selectors';
import {
  IClickedGoToMessagesAfterQuizAnalytics,
  IClickedToSeeAllQuizAnalytics,
  ILoadedExtendedCategoryPageAfterQuiz,
  ILoadedQuizSuccessScreenAnalytics,
  IUsedQuizCarouselAnalytics,
} from 'lib/quiz/analytics/actions';
import { Action, IApplicationState } from 'lib/types';
import { getVenueBooked } from 'lib/weddings/selectors';
import { QuizAnalyticsActions } from './types';

const quizAnalytics = (
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) => {
  const { type, payload } = action as
    | IClickedToSeeAllQuizAnalytics
    | IUsedQuizCarouselAnalytics
    | ILoadedQuizSuccessScreenAnalytics
    | IClickedGoToMessagesAfterQuizAnalytics
    | ILoadedExtendedCategoryPageAfterQuiz;
  const { track } = bridebookAnalytics.getMethods('quiz');
  const state = getState();
  const countryCode = getCountryCode(state);
  const locale = getWeddingLocale(state);
  const userEmail = state.users.user?.contacts?.email;
  const weddingId = state.weddings.profile.id;
  const collaborators = state.weddings.collaborators;
  const { isMobile: mobileApp } = getScreenType(state);
  const venueBooked = getVenueBooked(state);
  const previousPath = state.app.previousPath;

  const commonsProps = {
    collaborators,
    countryCode,
    locale,
    mobileApp,
    previousPath,
    profileType: 'user',
    userEmail,
    weddingId,
    venueBooked,
  };
  switch (type) {
    case QuizAnalyticsActions.CLICKED_TO_SEE_ALL_QUIZ:
      track({ event: 'Clicked to see all quiz', ...commonsProps, location: payload.location });
      break;
    case QuizAnalyticsActions.USED_QUIZ_CAROUSEL:
      track({ event: 'Used quiz carousel', ...commonsProps, location: payload.location });
      break;
    case QuizAnalyticsActions.LOADED_QUIZ_SUCCESS_SCREEN:
      track({
        event: 'Loaded quiz success screen',
        ...commonsProps,
        quizName: payload.quizName,
        enquiriesSent: payload.enquiriesSent,
      });
      break;

    case QuizAnalyticsActions.CLICKED_GO_TO_MESSAGES_AFTER_QUIZ:
      track({
        event: 'Clicked go to messages after quiz',
        ...commonsProps,
        quizName: payload.quizName,
        location: payload.location,
      });
      break;
    case QuizAnalyticsActions.LOADED_EXTENDED_CATEGORY_PAGE_AFTER_QUIZ:
      track({
        event: 'Loaded extended category page after quiz',
        quizName: payload.quizId,
        extendedSupplierCategory: payload.extendedSupplierCategory,
      });
      break;
    default:
      break;
  }
};

export default quizAnalytics;
