import { IconClass } from '@bridebook/ui/src/icons/constants';
import { IAchievementPopupIgnore } from 'lib/ui/types';

export { IconClass };

export const INITIAL_ACHIEVEMENT_HIDE_STATUS: IAchievementPopupIgnore = {
  status: 'none',
  achievementIds: [],
  mode: 'ignore',
};
