import produce from 'immer';
import { Weddings } from '@bridebook/models';
import {
  IDatePicker,
  IWedding,
  IWedding_Website_Section_QuestionAnswerField,
} from '@bridebook/models/source/models/Weddings.types';
import { recursiveSerializeTimestamps } from '@bridebook/toolbox/src/serialize-timestamps';
import {
  AccessControlActionTypes,
  IFetchCollaboratorsEmailsSuccessAction,
} from 'lib/access-control/action-types';
import { Action, IReducersImmer, IWeddingsState } from 'lib/types';
import { getReducerActions } from 'lib/utils';
import { EMPTY_WEBSITE_TEMPLATE } from 'lib/wedding-website/constants/emptyWebsiteTemplate';
import { IWeddingWebsiteOnboardingState } from 'lib/wedding-website/types';
import { AuthActionTypes, UserSetupCompletedAction } from '../auth/action-types';
import {
  IAddWebsitePhotoAction,
  IAddWebsiteQuestionAnswerAction,
  IFetchCountryBoundsSuccessAction,
  IRemoveWebsitePhotoAction,
  IRemoveWebsiteQuestionAnswerAction,
  IResetWebsiteDesignTabStateAction,
  IResetWebsiteDraftAction,
  ISetWebsiteDesignCustomiseTabOpenAction,
  ISetWebsiteDesignThemeTabOpenAction,
  ISetWebsiteDirtyAction,
  ISetWebsiteDraftAction,
  ISetWebsiteOnboardingModalActiveStepAction,
  ISetWebsitePartnersAction,
  ISetWebsitePinAction,
  ISetWebsitePublishedAction,
  ISetWebsiteSectionVisibilityAction,
  ISetWebsiteShouldPrefillAction,
  ISetWebsiteThemeAction,
  ISetWebsiteThemeFontAction,
  ISetWebsiteThemePaletteAction,
  ISetWebsiteWeddingDateAction,
  IStepWebsiteOnboardingBackwardAction,
  IStepWebsiteOnboardingForwardAction,
  IToggleWebsiteOnboardingModalAction,
  IUpdateInboxUnreadAction,
  IUpdatePartnerNamesAction,
  IUpdateWebsiteDraftAction,
  IUpdateWebsitePhotosAction,
  IUpdateWebsiteQuestionAnswerAction,
  IUpdateWebsiteSectionAction,
  IUpdateWebsiteTextFieldAction,
  IUpdateWeddingAction,
  IUpdateWeddingFieldAction,
  WeddingActionTypes,
} from './action-types';
import { IWeddingWebsiteDataState } from './types';

const weddingProfileDefault: IWedding = {
  ...Weddings.new('_'),
  id: '',
};

const WEBSITE_ONBOARDING_INITIAL_STATE: IWeddingWebsiteOnboardingState = {
  open: false,
  activeStep: 0,
  formConfig: {
    weddingDateNotConfirmed: false,
    venueNotConfirmed: false,
  },
};

export const initialWebsiteMetaState: IWeddingWebsiteDataState = {
  dirty: false,
  shouldPrefill: true,
  draft: {
    wedding: {
      partners: ['', ''],
      weddingDate: {
        d: null,
        m: null,
        y: null,
      },
    },
    website: EMPTY_WEBSITE_TEMPLATE,
  },
  onboarding: WEBSITE_ONBOARDING_INITIAL_STATE,
  design: {
    isThemesOpen: true,
    isCustomiseOpen: false,
  },
};

const initialState: IWeddingsState = {
  profile: {
    ...weddingProfileDefault,
    tasks: { ...weddingProfileDefault.tasks, initializedAt: 0 },
  },
  collaborators: [],
  loaded: false,
  countryLatLongBounds: {
    ne: { lat: -90, lon: 180 },
    sw: { lat: 90, lon: 180 },
  },
  listenersInitialised: false,
  inboxUnread: 0,
  loading: false,
  websiteMeta: initialWebsiteMetaState,
};

const reducers: IReducersImmer<IWeddingsState> = (draft: IWeddingsState) => ({
  [WeddingActionTypes.UPDATE_WEDDING]: (action: IUpdateWeddingAction) => {
    draft.profile = recursiveSerializeTimestamps(action.payload.wedding);

    draft.websiteMeta.draft.wedding = {
      partners: draft.profile.partners as [string, string],
      weddingDate: draft.profile.date || initialWebsiteMetaState.draft.wedding.weddingDate,
    };
    draft.websiteMeta.draft.website =
      action.payload.wedding.website || initialWebsiteMetaState.draft.website;

    draft.loading = false;
    draft.loaded = true;
  },
  [AuthActionTypes.USER_SETUP_COMPLETED]: (action: UserSetupCompletedAction) => {
    draft.profile = recursiveSerializeTimestamps(action.payload.wedding);

    draft.websiteMeta.draft.wedding = {
      partners: draft.profile.partners as [string, string],
      weddingDate: draft.profile.date || initialWebsiteMetaState.draft.wedding.weddingDate,
    };
    draft.websiteMeta.draft.website =
      action.payload.wedding.website || initialWebsiteMetaState.draft.website;

    draft.loading = false;
    draft.loaded = true;
  },

  [AuthActionTypes.USER_SETUP_STARTED]: () => {
    draft.loading = true;
  },

  [WeddingActionTypes.UPDATE_WEDDING_FIELD]: (action: IUpdateWeddingFieldAction) => {
    const { payload } = action;

    if (payload.name === 'date' && payload.value.date) {
      draft.profile.date = { ...draft.profile.date, ...payload.value.date } as IDatePicker;
      draft.websiteMeta.draft.wedding.weddingDate = draft.profile.date;
    }
  },

  [WeddingActionTypes.UPDATE_PARTNER_NAMES]: (action: IUpdatePartnerNamesAction) => {
    draft.profile.partners = action.payload;
    draft.websiteMeta.draft.wedding.partners = action.payload;
  },

  [WeddingActionTypes.UPDATE_INBOX_UNREAD]: (action: IUpdateInboxUnreadAction) => {
    draft.inboxUnread = action.payload.unreadCount;
  },

  [AccessControlActionTypes.FETCH_COLLABORATORS_EMAILS_SUCCESS]: (
    action: IFetchCollaboratorsEmailsSuccessAction,
  ) => {
    draft.collaborators = action.payload;
  },

  [WeddingActionTypes.FETCH_PROFILE_COUNTRY_BOUNDS_SUCCESS]: (
    action: IFetchCountryBoundsSuccessAction,
  ) => {
    draft.countryLatLongBounds = action.payload;
  },

  [WeddingActionTypes.GLOBAL_LISTENERS_INITIALISED]: () => {
    draft.listenersInitialised = true;
  },

  // Set weddings to initial state upon signout
  [AuthActionTypes.SIGN_OUT_COMPLETED]: () => initialState,

  [WeddingActionTypes.WEBSITE_SET_DIRTY]: (action: ISetWebsiteDirtyAction) => {
    draft.websiteMeta.dirty = action.payload;
  },
  [WeddingActionTypes.WEBSITE_SET_SHOULD_PREFILL]: (action: ISetWebsiteShouldPrefillAction) => {
    draft.websiteMeta.shouldPrefill = action.payload;
  },
  [WeddingActionTypes.WEBSITE_SET_DRAFT]: (action: ISetWebsiteDraftAction) => {
    draft.websiteMeta.draft = action.payload.draft;
    draft.websiteMeta.dirty =
      typeof action.payload.dirty === 'undefined' ? draft.websiteMeta.dirty : action.payload.dirty;
  },
  [WeddingActionTypes.WEBSITE_UPDATE_DRAFT]: (action: IUpdateWebsiteDraftAction) => {
    draft.websiteMeta.draft = {
      ...draft.websiteMeta.draft,
      ...action.payload.draft,
    };
    draft.websiteMeta.dirty = action.payload.dirty ?? true;
  },
  [WeddingActionTypes.WEBSITE_RESET_DRAFT]: (_: IResetWebsiteDraftAction) => {
    draft.websiteMeta.draft.wedding = {
      partners: draft.profile.partners as [string, string],
      weddingDate: draft.profile.date || initialWebsiteMetaState.draft.wedding.weddingDate,
    };
    draft.websiteMeta.draft.website =
      draft.profile.website || initialWebsiteMetaState.draft.website;
    draft.websiteMeta.dirty = false;
  },
  [WeddingActionTypes.WEBSITE_SET_THEME]: (action: ISetWebsiteThemeAction) => {
    draft.websiteMeta.draft.website.config.theme = action.payload.theme;
    draft.websiteMeta.dirty = action.payload.dirty ?? true;
  },
  [WeddingActionTypes.WEBSITE_SET_THEME_FONT]: (action: ISetWebsiteThemeFontAction) => {
    draft.websiteMeta.draft.website.config.theme.font = action.payload.font;
    draft.websiteMeta.dirty = action.payload.dirty ?? true;
  },
  [WeddingActionTypes.WEBSITE_SET_THEME_PALETTE]: (action: ISetWebsiteThemePaletteAction) => {
    draft.websiteMeta.draft.website.config.theme.palette = action.payload.palette;
    draft.websiteMeta.dirty = action.payload.dirty ?? true;
  },
  [WeddingActionTypes.WEBSITE_SET_PIN]: (action: ISetWebsitePinAction) => {
    draft.websiteMeta.draft.website.privacy.pin = action.payload.pin;
    draft.websiteMeta.dirty = action.payload.dirty ?? true;
  },
  [WeddingActionTypes.WEBSITE_SET_PUBLISHED]: (action: ISetWebsitePublishedAction) => {
    draft.websiteMeta.draft.website.privacy.published = action.payload.published;
    draft.websiteMeta.dirty = action.payload.dirty ?? true;
  },
  [WeddingActionTypes.WEBSITE_SET_PARTNERS]: (action: ISetWebsitePartnersAction) => {
    draft.websiteMeta.draft.wedding.partners = action.payload;
    draft.websiteMeta.dirty = true;
  },
  [WeddingActionTypes.WEBSITE_SET_WEDDING_DATE]: (action: ISetWebsiteWeddingDateAction) => {
    draft.websiteMeta.draft.wedding.weddingDate = action.payload;
    draft.websiteMeta.dirty = true;
  },
  [WeddingActionTypes.WEBSITE_UPDATE_SECTION]: (action: IUpdateWebsiteSectionAction) => {
    const { name, type } = action.payload;
    const sectionIndex = draft.websiteMeta.draft.website.sections.findIndex(
      (sec) => sec.name === name && sec.type === type,
    );
    if (sectionIndex === -1) {
      return;
    }
    draft.websiteMeta.draft.website.sections[sectionIndex] = {
      ...draft.websiteMeta.draft.website.sections[sectionIndex],
      ...(action.payload as (typeof draft.websiteMeta.draft.website.sections)[number]),
    };
    draft.websiteMeta.dirty = true;
  },
  [WeddingActionTypes.WEBSITE_SET_SECTION_VISIBILITY]: (
    action: ISetWebsiteSectionVisibilityAction,
  ) => {
    const sectionIndex = draft.websiteMeta.draft.website.sections.findIndex(
      (sec) => sec.name === action.payload.name,
    );
    if (sectionIndex !== -1) {
      draft.websiteMeta.draft.website.sections[sectionIndex].visible = action.payload.visible;
      draft.websiteMeta.dirty = true;
    }
  },
  [WeddingActionTypes.WEBSITE_ADD_QUESTION_ANSWER]: (action: IAddWebsiteQuestionAnswerAction) => {
    const sectionIndex = draft.websiteMeta.draft.website.sections.findIndex(
      (sec) => sec.name === action.payload.name,
    );
    if (sectionIndex !== -1) {
      (
        draft.websiteMeta.draft.website.sections[
          sectionIndex
        ] as IWedding_Website_Section_QuestionAnswerField
      ).data.push({
        question: action.payload.question,
        answer: action.payload.answer,
      });
      draft.websiteMeta.dirty = true;
    }
  },
  [WeddingActionTypes.WEBSITE_REMOVE_QUESTION_ANSWER]: (
    action: IRemoveWebsiteQuestionAnswerAction,
  ) => {
    const sectionIndex = draft.websiteMeta.draft.website.sections.findIndex(
      (sec) => sec.name === action.payload.name,
    );
    const isSectionValid =
      sectionIndex !== -1 &&
      draft.websiteMeta.draft.website.sections[sectionIndex].type === 'QuestionAnswerField' &&
      (
        draft.websiteMeta.draft.website.sections[
          sectionIndex
        ] as IWedding_Website_Section_QuestionAnswerField
      ).data.length > action.payload.index;
    if (isSectionValid) {
      (
        draft.websiteMeta.draft.website.sections[
          sectionIndex
        ] as IWedding_Website_Section_QuestionAnswerField
      ).data.splice(action.payload.index, 1);
      draft.websiteMeta.dirty = true;
    }
  },
  [WeddingActionTypes.WEBSITE_UPDATE_QUESTION_ANSWER]: (
    action: IUpdateWebsiteQuestionAnswerAction,
  ) => {
    const section = draft.websiteMeta.draft.website.sections.find(
      (sec) => sec.name === action.payload.name,
    );
    if (!section || section?.type !== 'QuestionAnswerField') return;
    section.data[action.payload.index] = {
      question: action.payload.question,
      answer: action.payload.answer,
    };
    draft.websiteMeta.dirty = true;
  },
  [WeddingActionTypes.WEBSITE_UPDATE_TEXT_FIELD]: (action: IUpdateWebsiteTextFieldAction) => {
    const section = draft.websiteMeta.draft.website.sections.find(
      (sec) => sec.name === action.payload.name,
    );
    if (!section || section?.type !== 'TextField') return;
    section.data.value = action.payload.value;
    draft.websiteMeta.dirty = true;
  },

  [WeddingActionTypes.WEBSITE_UPDATE_PHOTOS]: (action: IUpdateWebsitePhotosAction) => {
    const section = draft.websiteMeta.draft.website.sections.find(
      (sec) => sec.name === action.payload.name,
    );
    if (!section || section?.type !== 'PhotosField') return;
    section.data = action.payload.data;
    draft.websiteMeta.dirty = true;
  },
  [WeddingActionTypes.WEBSITE_ADD_PHOTO]: (action: IAddWebsitePhotoAction) => {
    const section = draft.websiteMeta.draft.website.sections.find(
      (sec) => sec.name === action.payload.name,
    );
    if (!section || section?.type !== 'PhotosField') return;
    section.data.push({ path: action.payload.photo });
    draft.websiteMeta.dirty = true;
  },
  [WeddingActionTypes.WEBSITE_REMOVE_PHOTO]: (action: IRemoveWebsitePhotoAction) => {
    const section = draft.websiteMeta.draft.website.sections.find(
      (sec) => sec.name === action.payload.name,
    );
    if (!section || section?.type !== 'PhotosField') return;
    section.data = section.data.filter((photo) => photo.path !== action.payload.photo);
    draft.websiteMeta.dirty = true;
  },
  [WeddingActionTypes.WEBSITE_ONBOARDING_MODAL_TOGGLE_OPEN]: (
    action: IToggleWebsiteOnboardingModalAction,
  ) => {
    draft.websiteMeta.onboarding.open = action.payload;
  },
  [WeddingActionTypes.WEBSITE_ONBOARDING_SET_ACTIVE_STEP]: (
    action: ISetWebsiteOnboardingModalActiveStepAction,
  ) => {
    draft.websiteMeta.onboarding.activeStep = action.payload;
  },
  [WeddingActionTypes.WEBSITE_ONBOARDING_STEP_BACKWARD]: (
    _: IStepWebsiteOnboardingBackwardAction,
  ) => {
    draft.websiteMeta.onboarding.activeStep -= 1;
  },
  [WeddingActionTypes.WEBSITE_ONBOARDING_STEP_FORWARD]: (
    _: IStepWebsiteOnboardingForwardAction,
  ) => {
    draft.websiteMeta.onboarding.activeStep += 1;
  },
  [WeddingActionTypes.WEBSITE_DESIGN_SET_THEME_TAB_OPEN]: (
    action: ISetWebsiteDesignThemeTabOpenAction,
  ) => {
    draft.websiteMeta.design.isThemesOpen = action.payload;
  },
  [WeddingActionTypes.WEBSITE_DESIGN_SET_CUSTOMISE_TAB_OPEN]: (
    action: ISetWebsiteDesignCustomiseTabOpenAction,
  ) => {
    draft.websiteMeta.design.isCustomiseOpen = action.payload;
  },
  [WeddingActionTypes.WEBSITE_DESIGN_RESET_TAB_STATE]: (_: IResetWebsiteDesignTabStateAction) => {
    draft.websiteMeta.design = initialWebsiteMetaState.design;
  },
});

const reducersActions = getReducerActions(reducers);

/*
  This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IWeddingsState = initialState, action: Action): IWeddingsState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
